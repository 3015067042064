import { MESSAGES_VALIDATE } from '@/config'
import { z } from 'zod'

export const AUTH_SCHEMA = {
  USERNAME: 'username',
  PASSWORD: 'password',
  PERSIST: 'persist'
}

export const AUTH_FORGOT_PASSWORD_SCHEMA = {
  USERNAME: 'username',
  EMAIL: 'email'
}

export const AUTH_SCHEMA_VALIDATION = {
  [AUTH_SCHEMA.USERNAME]: z.string().min(1, MESSAGES_VALIDATE.REQUIRED),
  [AUTH_SCHEMA.PASSWORD]: z.string().min(1, MESSAGES_VALIDATE.REQUIRED)
}

export const AUTH_FORGOT_PASSWORD_SCHEMA_VALIDATION = {
  [AUTH_FORGOT_PASSWORD_SCHEMA.USERNAME]: z.string().min(1, MESSAGES_VALIDATE.REQUIRED),
  [AUTH_FORGOT_PASSWORD_SCHEMA.EMAIL]: z.string().email(MESSAGES_VALIDATE.EMAIL)
}

export const AUTH_RESET_PASSWORD_SCHEMA = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword'
}

export const AUTH_RESET_PASSWORD_SCHEMA_VALIDATION = {
  [AUTH_RESET_PASSWORD_SCHEMA.PASSWORD]: z.string().min(8, MESSAGES_VALIDATE.REQUIRED)
}
