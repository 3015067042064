import useAuth from '../hooks/use-auth'
import { Link, useNavigate } from 'react-router-dom'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@/router/paths'
import LayoutAuth from '../components/layout-auth'
import { TypographyParagraph } from '@/components/ui/typography/paragraph'
import { joinPath } from '@/utils'
import { Button } from '@/components/ui/button'
import { ROUTES_AUTH } from '../router/auth-router'

const ForgotPasswordSuccess = () => {
  const navigate = useNavigate()
  const { auth } = useAuth()

  if (auth?.id) { navigate(`/${PRIVATE_ROUTE.ADMIN}`, { replace: true }) }

  return (
    <LayoutAuth
      title='Correo electrónico enviado'
      description='Es posible que tarde unos minutos en recibir el enlace de recuperación de contraseña.'
    >
      <TypographyParagraph>Si no recibe este enlace, comuniquese con su administrador.</TypographyParagraph>
      <Button asChild variant='link'>
        <Link to={joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)}>Iniciar sesión</Link>
      </Button>
    </LayoutAuth>
  )
}

export default ForgotPasswordSuccess
