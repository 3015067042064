import React, { useEffect } from 'react'
import useRefreshToken from '../hooks/use-refresh-token'
import useAuth from '../hooks/use-auth'
import { Outlet } from 'react-router-dom'
import LoadingBrand from '@/components/loading-brand'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const refresh = useRefreshToken()
  const { auth, persist } = useAuth()

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)
  }, [])

  // useEffect(() => {
  //   console.log('isLoading', isLoading)
  //   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
  // }, [])

  return (
    <>
      {
        !persist
          ? <Outlet />
          : isLoading
            ? (
                <div className='flex justify-center items-center h-screen w-screen'>
                  <LoadingBrand />
                </div>
              )
            : <Outlet />
      }
    </>
  )
}

export default PersistLogin
