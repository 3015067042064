import { cn } from '@/lib/utils'
import { useField } from './form-context'
import { type ReactNode } from 'react'

type Types = 'error' | 'warning' | 'info'

export const validateThereNotices = (messages: string | string[]) => {
  if (Array.isArray(messages) && messages.length === 0) return false
  if (messages === '' || messages === undefined) return false
  return true
}

export const FormMessages = ({ messages, type = 'error', className }: { messages?: string | string[] | undefined | ReactNode, type?: Types, className?: string }) => {
  // console.log('messages', messages)
  // return null
  const { id, messages: messagesContext } = useField()

  const localOrGlobalNotices = messages ?? messagesContext

  if (localOrGlobalNotices === '' || localOrGlobalNotices === undefined) return null
  if (Array.isArray(localOrGlobalNotices) && localOrGlobalNotices?.length === 0) return null

  const isArray = Array.isArray(localOrGlobalNotices)
  // const titleType = type === 'error' ? 'Errores' : type === 'warning' ? 'Avisos' : type === 'info' ? 'Información' : ''

  return (
    <div
      id={`${id}-messages`}
      className={cn([
        'text-sm flex flex-col text-gray-700 dark:text-gray-400',
        className
      ])}
    >
      {
        isArray
          ? (
            <div>
              {/* <div className="flex items-center mb-1">
                { type === 'info' && <InfoIcon className='h-3 w-3 text-blue-600' />}
                {type === 'warning' && <AlertTriangle className='h-3 w-3 text-yellow-500' />}
                {type === 'error' && <XCircle className='h-3 w-3 text-red-500' />}
                <span className={cn([
                  'ml-1 font-semibold text-neutral',

                ])}>{titleType}</span>
              </div> */}
              <ul id={`${id}-messages`}>
                {
                  localOrGlobalNotices.map((message, index) => (
                    <li className={cn([
                      'ml-4 list-disc text-xs leading-tight',
                      type === 'error' && 'text-red-500',
                      type === 'warning' && 'text-yellow-600',
                      type === 'info' && 'text-blue-600'
                    ])} key={index}>{message}</li>
                  ))
                }
              </ul>
            </div>

            )
          : (
            <span id={`${id}-messages`} className={cn([
              'text-xs leading-tight',
              type === 'error' && 'text-red-500',
              type === 'warning' && 'text-yellow-600',
              type === 'info' && 'text-blue-600'
            ])}>{localOrGlobalNotices}</span>
            )
      }
    </div>
  )
}
