import { cn } from '@/lib/utils'
import { Loader } from 'lucide-react'
import { type ReactNode } from 'react'

const Loading = ({ className, children }: { className?: string, children?: ReactNode }) => {
  return (
    <div className={cn([
      'flex items-center justify-center text-sm text-muted-foreground',
      className
    ])}>
      <Loader className="mr-2 h-4 w-4 animate-spin" />
      {children ?? 'Cargando...'}
    </div>
  )
}

export default Loading
