import { type ReactNode } from 'react'
import { Routes, Route, type RoutesProps } from 'react-router-dom'

const RoutesWithNotFound = ({ children, ...props }: { children: ReactNode } & RoutesProps) => (
    <Routes {...props}>
      {children}
      <Route path='*' element={<h1>404 Not Found</h1>} />
    </Routes>
)

export default RoutesWithNotFound
