export enum EnumIdentificationCard {
  D = 'D',
  DFD = 'DFD',
  P = 'P',
  CN = 'CN',
  DF = 'DF'
}

export const NAME_IDENTIFICATION_CARD = {
  D: 'DPI',
  DFD: 'DPI Extranjero Domiciliado',
  P: 'Pasaporte',
  CN: 'Certificado de nacimiento',
  DF: 'Genérico'
} as const

export const TYPE_IDENTIFICATION_CARD = {
  DPI: 'D',
  DPI_FOREIGN_DOMICILED: 'DFD',
  PASSPORT: 'P',
  BIRTH_CERTIFICATE: 'CN',
  GENERIC: 'DF'
} as const

export type TypesIdentificationCard = 'D' | 'DFD' | 'P' | 'CN' | 'DF'

export const CATALOG_TYPE_IDENTIFICATION_CARD = [
  { value: TYPE_IDENTIFICATION_CARD.DPI, label: NAME_IDENTIFICATION_CARD.D },
  { value: TYPE_IDENTIFICATION_CARD.PASSPORT, label: NAME_IDENTIFICATION_CARD.P }
]

export const CATALOG_TYPE_IDENTIFICATION_BENEFICIARIES = [
  { value: TYPE_IDENTIFICATION_CARD.DPI, label: NAME_IDENTIFICATION_CARD.D },
  { value: TYPE_IDENTIFICATION_CARD.DPI_FOREIGN_DOMICILED, label: NAME_IDENTIFICATION_CARD.DFD },
  { value: TYPE_IDENTIFICATION_CARD.PASSPORT, label: NAME_IDENTIFICATION_CARD.P },
  { value: TYPE_IDENTIFICATION_CARD.BIRTH_CERTIFICATE, label: NAME_IDENTIFICATION_CARD.CN }
]

export const MASK_IDENTIFICATION_CARD_SCHEMA = {
  D: '0000 00000 0000',
  DFD: '0000 00000 0000',
  P: '',
  CN: '0000 00000 0000',
  DF: ''
} as const

export const PLACEHOLDER_IDENTIFICATION_CARD_SCHEMA = {
  D: '____ _____ ____',
  DFD: '____ _____ ____',
  P: '',
  CN: '____ _____ ____',
  DF: ''
} as const
