import { createContext, useContext } from 'react'

export interface FieldContextValue {
  messages: string | string[]
  required: boolean
  disabled: boolean
  name?: string | undefined
  id: string
}

export const FormContext = createContext<FieldContextValue>({ messages: '', name: '', id: '', required: false, disabled: false })
export const useField = () => useContext(FormContext)
