import useAuth from '../hooks/use-auth'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@/router/paths'
import { HttpStatus } from '@/config/http-status'
import { Form as FormHouse, Field as FieldHouse } from 'houseform'
import { CODE_ERRORS } from '@/config/code-errors'
import { FormField } from '@/components/form/form-field'
import { FormLabel } from '@/components/form/form-label'
import { FormInput } from '@/components/form/form-input'
import { FormMessages } from '@/components/form/form-messages'
import { Button } from '@/components/ui/button'
import { Loader } from 'lucide-react'
import { type IUserForgotPassword } from '@/features/users/models/user'
import { forgotPassword } from '../auth.service'
import { ROUTES_AUTH } from '../router/auth-router'
import { AUTH_FORGOT_PASSWORD_SCHEMA, AUTH_FORGOT_PASSWORD_SCHEMA_VALIDATION } from '../auth.schema'
import LayoutAuth from '../components/layout-auth'
import { joinPath } from '@/utils'

const ForgotPassword = () => {
  const navigate = useNavigate()

  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)

  const onSendEmailForgotPassword = async (values: IUserForgotPassword) => {
    try {
      setLoading(true)
      const { status, data } = await forgotPassword(values)
      if (status === HttpStatus.OK && data?.success) {
        navigate(joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.FORGOT_PASSWORD_SUCCESS), {
          replace: true
        })
        setLoading(false)
      } else if (status === HttpStatus.UNAUTHORIZED) {
        setLoading(false)
        toast.error(CODE_ERRORS[data.message])
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  if (auth?.id) { navigate(`/${PRIVATE_ROUTE.ADMIN}`, { replace: true }) }

  return (
    <LayoutAuth
      title='Recuperación de contraseña'
      description='Ingresa tu correo electrónico y nombre de usuario para poder enviarte tu correo de restauración.'
    >
      <FormHouse
        onSubmit={onSendEmailForgotPassword}
      >
        {
          ({ submit }) =>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                submit()
              }}
              className='grid grid-cols-1 w-full gap-6'
            >
              <FieldHouse
                name={AUTH_FORGOT_PASSWORD_SCHEMA.USERNAME}
                onBlurValidate={AUTH_FORGOT_PASSWORD_SCHEMA_VALIDATION.username}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Usuario</FormLabel>
                      <FormInput className='lowercase' value={value} autoComplete='username' onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              <FieldHouse
                name={AUTH_FORGOT_PASSWORD_SCHEMA.EMAIL}
                onBlurValidate={AUTH_FORGOT_PASSWORD_SCHEMA_VALIDATION.email}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Correo electrónico</FormLabel>
                      <FormInput className='lowercase' type='email' autoComplete='email' value={value} onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              <Button type='submit'>
                {
                  loading
                    ? (<>
                      <Loader className="mr-2 h-4 w-4 animate-spin" />Enviando...
                    </>)
                    : 'Enviar correo de restauración'
                }

              </Button>
            </form>

        }
      </FormHouse>
      <Button variant={'link'} asChild><Link to={joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)}>Iniciar sesión</Link></Button>
    </LayoutAuth>
  )
}

export default ForgotPassword
