import { type ReactNode } from 'react'
import { useField } from './form-context'
import { validateThereNotices } from './form-messages'

export const FormDescription = ({ children, ...props }: { children?: ReactNode | string }) => {
  const { id, messages } = useField()

  const hasNotices = validateThereNotices(messages)
  if ((!children && children !== '') || hasNotices) return null

  return (
    <span className='text-xs text-neutral-500 leading-tight' id={`${id}-desc`} {...props}>
      { children }
    </span>
  )
}
