import { useRef, type ChangeEventHandler } from 'react'
import { useField } from './form-context'
import { validateThereNotices } from './form-messages'
import { Input } from '../ui/input'
import { cn } from '@/lib/utils'
import { format, parse } from 'date-fns'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

export const FormInput =
  ({ onChange, disabled = false, type = 'text', className, ...props }: InputProps) => {
    const ref = useRef<HTMLInputElement>(null)
    const { id, messages, name, required, disabled: disabledField } = useField()
    const isDisabled = disabled || disabledField
    const hasMessages = validateThereNotices(messages)
    const ariaDescription = hasMessages ? `${id}-messages` : `${id}-desc`

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (isDisabled) return
      if (onChange) onChange(e)
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (type !== 'date') return
      const pastedValue = e.clipboardData.getData('Text').trim()
      const regexValidateDate = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      const isDateValid = regexValidateDate.test(pastedValue)
      if (!isDateValid) return
      const dateFormat = pastedValue.includes('/') ? 'dd/MM/yyyy' : pastedValue.includes('-') ? 'dd-MM-yyyy' : pastedValue.includes('.') ? 'dd.MM.yyyy' : null
      if (!dateFormat) return
      const parsedDate = parse(pastedValue, dateFormat, new Date())
      const formattedDateString = format(parsedDate, 'yyyy-MM-dd')
      if (onChange) onChange({ ...e, target: { value: formattedDateString } })
    }

    return (
      <Input
        ref={ref}
        type={type}
        id={id}
        name={name}
        required={required}
        disabled={isDisabled}
        onChange={handleChange}
        aria-required={required}
        aria-describedby={ariaDescription}
        aria-invalid={hasMessages}
        aria-disabled={isDisabled}
        onPaste={type === 'date' ? (e) => { handlePaste(e) } : undefined}
        className={cn([
          'uppercase',
          '[&::-webkit-calendar-picker-indicator]:dark:invert',
          className
        ])}
        {...props}
      />
    )
  }

FormInput.displayName = 'FormInput'
