import { ModeToggle } from '@/components/mode-toggle'
import { TypographyH2 } from '@/components/ui/typography/h2'
import { TypographyMuted } from '@/components/ui/typography/muted'
import { type ReactNode, type FC } from 'react'

/**
 * Layout for auth pages
 * @param { Element } children
 * @param { String } title
 * @param { String } description
 * @returns JSX.Element
 */
interface Props {
  children: React.ReactNode
  title?: string
  description?: string | ReactNode
}

const LayoutAuth: FC<Props> = ({ children, title = '', description = '' }) => {
  return (
    <div className='bg-gray-50 dark:bg-background w-full h-screen flex items-center justify-center relative'>
      <ModeToggle className='absolute top-4 right-4' />
      <div className='w-full lg:dark:border dark:border-border max-w-screen-sm p-8 lg:p-14 bg-transparent lg:bg-background lg:dark:bg-slate-900 lg:shadow-xl flex flex-col items-center gap-6 transition-colors duration-150'>
        <img className='h-[75px] w-[75px] dark:bg-white dark:p-1 dark:rounded-md' src='/images/logo.svg' alt='Logo' />
        <div className='flex flex-col gap-2'>
          <TypographyH2 className='text-center'>{title}</TypographyH2>
          <TypographyMuted className='text-center'>{description}</TypographyMuted>
        </div>
        {children}
      </div>
    </div>
  )
}

export default LayoutAuth
