import { EnumIdentificationCard, type TypesIdentificationCard } from '@/catalogs/identifications'
import { EnumMigrationStatus } from '@/catalogs/migration-status'
import { type LastName } from '@/models/last-name'
import { type Name } from '@/models/name'

export const buildName = (name: Name | undefined, lastName: LastName | undefined): string => {
  let fullname = ''
  if (name === undefined || lastName === undefined) return fullname
  if (name?.first) fullname += name.first
  if (name?.second) fullname += ` ${name.second}`
  if (name?.others) fullname += ` ${name.others}`
  if (lastName?.first) fullname += ` ${lastName.first}`
  if (lastName?.second) fullname += ` ${lastName.second}`
  if (lastName?.married) fullname += ` DE ${lastName.married}`
  return fullname
}

export const getFullName = (name, lastName) => {
  let fullName = ''
  let fullLastName = ''

  Object.keys(name).forEach(function (key) {
    if (name[key] !== '') fullName += ' ' + name[key]
  })

  Object.keys(lastName).forEach(function (key) {
    if (lastName[key] !== '') fullLastName += ' ' + lastName[key]
  })
  return `${fullName}${fullLastName}`.trim()
}

// Mark: - Scripts
export const numberToLetters = (num, considerLeadingZero = false) => {
  let strNum = String(num)
  let result = ''

  if (considerLeadingZero && strNum.startsWith('0')) {
    result += 'Cero '
    strNum = strNum.substring(1)
  }

  const data = {
    numero: Number(strNum),
    enteros: Math.floor(Number(strNum)),
    decimales: Math.round(Number(strNum) * 100) - Math.floor(Number(strNum)) * 100,
    letrasDecimales: ''
  }

  if (data.decimales > 0) data.letrasDecimales = ' Punto ' + Trillones(data.decimales)

  if (data.enteros === 0) return result + 'Cero' + data.letrasDecimales
  if (data.enteros === 1) return result + Trillones(data.enteros) + data.letrasDecimales
  else return result + Trillones(data.enteros) + data.letrasDecimales
}

export const Unidades = (num) => {
  const aLetras = {
    1: 'Uno',
    2: 'Dos',
    3: 'Tres',
    4: 'Cuatro',
    5: 'Cinco',
    6: 'Seis',
    7: 'Siete',
    8: 'Ocho',
    9: 'Nueve'
  }

  return aLetras[num] || ''
} // Unidades()

export const Decenas = (num) => {
  const decena = Math.floor(num / 10)
  const unidad = num - decena * 10

  const aLetras = {
    1: (() => {
      const aLetra = {
        0: 'Diez',
        1: 'Once',
        2: 'Doce',
        3: 'Trece',
        4: 'Catorce',
        5: 'Quince'
      }
      return aLetra[unidad] || 'Dieci' + Unidades(unidad).toLowerCase()
    })(),
    2: unidad === 0 ? 'Veinte' : 'Veinti' + Unidades(unidad).toLowerCase(),
    3: DecenasY('Treinta', unidad),
    4: DecenasY('Cuarenta', unidad),
    5: DecenasY('Cincuenta', unidad),
    6: DecenasY('Sesenta', unidad),
    7: DecenasY('Setenta', unidad),
    8: DecenasY('Ochenta', unidad),
    9: DecenasY('Noventa', unidad),
    0: Unidades(unidad)
  }

  return aLetras[decena] || ''
} // Decenas()

const DecenasY = (strSin, numUnidades) => {
  if (numUnidades > 0) return strSin + ' Y ' + Unidades(numUnidades)
  return strSin
} // DecenasY()

const Centenas = (num) => {
  const centenas = Math.floor(num / 100)
  const decenas = num - centenas * 100

  const aLetras = {
    1: decenas > 0 ? 'Ciento ' + Decenas(decenas) : 'Cien',
    2: 'Doscientos ' + Decenas(decenas),
    3: 'Trescientos ' + Decenas(decenas),
    4: 'Cuatrocientos ' + Decenas(decenas),
    5: 'Quinientos ' + Decenas(decenas),
    6: 'Seiscientos ' + Decenas(decenas),
    7: 'Setecientos ' + Decenas(decenas),
    8: 'Ochocientos ' + Decenas(decenas),
    9: 'Novecientos ' + Decenas(decenas)
  }

  return aLetras[centenas] || Decenas(decenas)
} // Centenas()

const Seccion = (num, divisor, strSingular, strPlural) => {
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  let letras = ''

  if (cientos > 0) {
    if (cientos > 1) letras = Centenas(cientos) + ' ' + strPlural
    else letras = strSingular
  }

  if (resto > 0) letras += ''

  return letras
} // Seccion()

const Miles = (num) => {
  const divisor = 1000
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  const strMiles = Seccion(num, divisor, 'Un mil', 'Mil')
  const strCentenas = Centenas(resto)

  if (strMiles === '') return strCentenas
  return strMiles + ' ' + strCentenas
} // Miles()

const Millones = (num) => {
  const divisor = 1000000
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  const strMillones = Seccion(num, divisor, 'Un millon', 'millones')
  const strMiles = Miles(resto)

  if (strMillones === '') return strMiles
  return strMillones + ' ' + strMiles
} // Millones()

const MilesMillones = (num) => {
  const divisor = 1000000000
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  const setMilMillones = Seccion(num, divisor, 'Un mil millon', 'mil millones')
  const setMillones = Millones(resto)

  if (setMilMillones === '') return setMillones
  return setMilMillones + ' ' + setMillones
} // Millones()

const Billones = (num) => {
  const divisor = 1000000000000
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  const setBillones = Seccion(num, divisor, 'Un billon', 'billones')
  const setMilMillones = MilesMillones(resto)

  if (setBillones === '') return setMilMillones
  return setBillones + ' ' + setMilMillones
} // Billones()

const Trillones = (num) => {
  const divisor = 1000000000000000
  const cientos = Math.floor(num / divisor)
  const resto = num - cientos * divisor

  const setTrillones = Seccion(num, divisor, 'Un trillon', 'trillones')
  const setBillones = Billones(resto)

  if (setTrillones === '') return setBillones
  return setTrillones + ' ' + setBillones
} // Trillones()

export const cuiIsValid = (cui: string) => {
  if (!cui) return false

  const cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/

  if (!cuiRegExp.test(cui)) return false

  const numberId = cui.replace(/\s/, '')

  const depto = parseInt(numberId.substring(9, 11), 10)
  const muni = parseInt(numberId.substring(11, 13))
  const numero = numberId.substring(0, 8)
  const verificador = parseInt(numberId.substring(8, 9))

  // Se asume que la codificación de Municipios y
  // departamentos es la misma que esta publicada en
  // http://goo.gl/EsxN1a

  // Listado de municipios actualizado segun:
  // http://goo.gl/QLNglm

  // Este listado contiene la cantidad de municipios
  // existentes en cada departamento para poder
  // determinar el código máximo aceptado por cada
  // uno de los departamentos.

  const munisPorDepto = [
    /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
    /* 02 - El Progreso tiene:    */ 8 /* municipios. */,
    /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
    /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
    /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
    /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
    /* 07 - Sololá tiene:         */ 19 /* municipios. */,
    /* 08 - Totonicapán tiene:    */ 8 /* municipios. */,
    /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
    /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
    /* 11 - Retalhuleu tiene:     */ 9 /* municipios. */,
    /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
    /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
    /* 14 - Quiché tiene:         */ 21 /* municipios. */,
    /* 15 - Baja Verapaz tiene:   */ 8 /* municipios. */,
    /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
    /* 17 - Petén tiene:          */ 14 /* municipios. */,
    /* 18 - Izabal tiene:         */ 5 /* municipios. */,
    /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
    /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
    /* 21 - Jalapa tiene:         */ 7 /* municipios. */,
    /* 22 - Jutiapa tiene:        */ 17 /* municipios. */
  ]

  if (depto === 0 || muni === 0) {
    // console.log("CUI con código de municipio o departamento inválido.");
    return false
  }

  if (depto > munisPorDepto.length) {
    // console.log("CUI con código de departamento inválido.");
    return false
  }

  if (muni > munisPorDepto[depto - 1]) {
    // console.log("CUI con código de municipio inválido.");
    return false
  }

  // Se verifica el correlativo con base
  // en el algoritmo del complemento 11.
  let total = 0

  for (let i = 0; i < numero.length; i++) {
    total += parseInt(numero[i]) * (i + 2)
  }

  const modulo = (total % 11)

  // console.log("CUI con módulo: " + modulo);
  return modulo === verificador
}

export const getTextOfTypeDocument = ({
  type, format = {
    letter: '',
    format: '',
    full: ''
  }
}) => {
  let identificationText = ''
  const formated = format.format

  // TODO: Cambiar validacion con el catalogo
  if (type === 'D' || type === 'CN') identificationText = `Documento Personal de Identificación -DPI- con el Código Único de Identificación -CUI- ${format.letter.toLowerCase()} (${formated})`
  if (type === 'P') identificationText = `Pasaporte ${format.letter.toLowerCase()} (${formated})`
  return identificationText
}
interface Numbers {
  full: string
  correlative?: {
    full: string
    firstPart: string
    secondPart: string
    secondPartWithChecker: string
  }
  checker?: string
  department?: string
  municipality?: string
}
interface FormatOfTypeDocument {
  numbers: Numbers
  format: string
  letter: string
}

export const getFormatOfTypeDocument = ({ type, number }: { type: EnumIdentificationCard, number: string }): FormatOfTypeDocument => {
  if (type === 'D' || type === 'CN') {
    if (cuiIsValid(number)) {
      const numbers = {
        full: number,
        correlative: {
          full: number.substring(0, 8),
          firstPart: number.substring(0, 4),
          secondPart: number.substring(4, 8),
          secondPartWithChecker: number.substring(4, 9)
        },
        checker: number.substring(8, 9),
        department: number.substring(9, 11),
        municipality: number.substring(11, 13)
      }

      const depAndMun = `${numbers.department}${numbers.municipality}`
      const format = `${numbers.correlative.firstPart} ${numbers.correlative.secondPartWithChecker} ${depAndMun}`

      const textDepAndMun = numbers.department[0] === '0' ? `Cero ${numberToLetters(depAndMun)}` : numberToLetters(depAndMun)

      const letter = `${numberToLetters(numbers.correlative.firstPart)}, ${numberToLetters(numbers.correlative.secondPartWithChecker)}, ${textDepAndMun}`
      return {
        numbers,
        format,
        letter
      }
    }
  }

  return {
    numbers: {
      full: number
    },
    format: number,
    letter: (/^[0-9]*$/).test(number) ? numberToLetters(number) : number
  }
}

export const getDateIntFormat = ({
  date,
  options = { year: 'numeric', month: 'long', day: 'numeric' },
  locale = 'es-GT'
}) => {
  const fecha = new Date(date)
  return fecha.toLocaleDateString(locale, options)
}

export const getAge = (dateString) => {
  const hoy = new Date()
  const fechaNacimiento = new Date(dateString)
  let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
  const diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
  if (
    diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
  ) {
    edad--
  }
  return edad
}

/**
 *
 * @param {*} date
 * @returns {{
 *  day: {
 *   letter: string,
 *    date: string,
 *    numeric: number,
 *  },
 *  month: {
 *    date: string,
 *    letter: string,
 *    numeric: number,
 *  },
 *  year: {
 *    date: number,
 *    letter: string
 * }} | boolean} format
 */

export interface DateFormat {
  formated: string
  day: {
    letter: string
    date: string
    numeric: number
  }
  month: {
    date: string
    letter: string
    numeric: number
  }
  year: {
    date: number
    letter: string
  }
}

export const getDateFormat = (date: Date | string, options: {
  formated?: 'letters' | 'numbers'
} = {
  formated: 'letters'
}): DateFormat | undefined => {
  if (!date) return undefined
  const newDate = date instanceof Date ? date : new Date(`${date}T00:00:00`)

  const DAYS = [
    /* 0 */ 'Domingo',
    /* 1 */ 'Lunes',
    /* 2 */ 'Martes',
    /* 3 */ 'Miércoles',
    /* 4 */ 'Jueves',
    /* 5 */ 'Viernes',
    /* 6 */ 'Sábado'
  ]

  const MESES = [
    /* 0 */ 'Enero',
    /* 1 */ 'Febrero',
    /* 2 */ 'Marzo',
    /* 3 */ 'Abril',
    /* 4 */ 'Mayo',
    /* 5 */ 'Junio',
    /* 6 */ 'Julio',
    /* 7 */ 'Agosto',
    /* 8 */ 'Septiembre',
    /* 9 */ 'Octubre',
    /* 10 */ 'Noviembre',
    /* 11 */ 'Diciembre'
  ]

  let formated = ''

  const format = {
    day: {
      letter: numberToLetters(newDate.getDate()),
      date: DAYS[newDate.getDay()],
      numeric: newDate.getDate()
    },
    month: {
      date: MESES[newDate.getMonth()],
      letter: numberToLetters(newDate.getMonth() + 1),
      numeric: newDate.getMonth() + 1
    },
    year: {
      date: newDate.getFullYear(),
      letter: numberToLetters(newDate.getFullYear())
    }
  }

  if (options.formated === 'numbers') {
    formated = `${format.day.numeric} de ${format.month.numeric} de ${format.year.date}`
  }

  if (options.formated === 'letters') {
    formated = `${format.day.letter} de ${format.month.date} de ${format.year.letter}`
  }

  return {
    formated,
    ...format
  }
}

export const formatElectronicPowerRegisterNumber = (number) => {
  if (!number) return ''

  let data = ''

  const splitNumber = number.split('-')

  splitNumber[0] && typeof +splitNumber[0] === 'number' ? data += numberToLetters(splitNumber[0]).toLowerCase() : data += splitNumber[0]

  if (splitNumber?.[1]) {
    typeof splitNumber[1] === 'number' ? data += ` guión ${numberToLetters(splitNumber[1]).toLowerCase()}` : data += ` guión ${splitNumber[1]}`
  }

  return data
}

export const transformFirtsLetterWord = (word: string) => {
  const words = word.split(' ')
  const newWords = words.map((w) => {
    return w.charAt(0).toUpperCase() + w.substring(1)
    // return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()
  })
  return newWords.join(' ')
}

export function joinPath (...paths: string[]) {
  return paths.join('/').replace(/\/+/g, '/')
}

const specialCharacters = {
  '-': 'guión',
  ':': 'dos puntos',
  ',': 'coma',
  '.': 'punto',
  '!': 'exclamación',
  '?': 'interrogación'
} as const

export function getCharacterName (character: string): string {
  return specialCharacters[character] || ''
}

export function splitText (input: string): string[] {
  const placeholders = []
  let placeholderIndex = 0

  // Replace groups enclosed in $ or % with placeholders
  input = input.replace(/(\$[^\$]*\$)|(\%[^\%]*\%)/g, (match) => {
    placeholders[placeholderIndex] = match
    return `PLACEHOLDER${placeholderIndex++}`
  })

  // Split text into words
  const words = input.split(/(\s+|\-|\.|\,|\:|\;|\!|\?|(?<=\D)(?=\d)|(?<=\d)(?=\D))/g).filter(Boolean)

  // Replace placeholders with original groups and remove the element to the right
  for (let i = 0; i < words.length; i++) {
    if (words[i].startsWith('PLACEHOLDER')) {
      words[i] = placeholders[Number(words[i].slice(11))]
      words.splice(i + 1, 1)
    }
  }

  return words
}

export const formatText = (text: string | undefined): string => {
  if (!text) return ''
  if (text === '') return text
  const words = splitText(text)
  const newWords = words.map((w) => {
    if (w === ' ') return ''
    if (w.startsWith('$') && w.endsWith('$')) {
      return ' ' + w.slice(1, -1)
    } else if (w.startsWith('%') && w.endsWith('%')) {
      return w.slice(1, -1)
    } else if (!isNaN(Number(w)) && !w.startsWith('$') && !w.startsWith('%')) {
      return ' ' + numberToLetters(w, true)
    } else if (getCharacterName(w) && !w.startsWith('$') && !w.startsWith('%')) {
      return ' ' + getCharacterName(w)
    } else {
      return ' ' + w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()
    }
  })
  return newWords.join('').trim()
}

export const getFormatByIdentification = ({
  type,
  number,
  migrationStatus,
  options = {
    textTransformFormated: 'normal'
  },
  country
}: {
  type: TypesIdentificationCard
  number: string
  migrationStatus?: EnumMigrationStatus | string
  country?: string
  options?: {
    textTransformFormated: 'normal' | 'uppercase' | 'lowercase' | 'capitalize'
  }
}
) => {
  if (type === EnumIdentificationCard.D || type === EnumIdentificationCard.DFD || type === EnumIdentificationCard.CN) {
    if (!cuiIsValid(number)) {
      return `Documento Personal de Identificación -DPI- con el Código Único de Identificación -CUI- ${number} (${number}) extendido por el Registro Nacional de las Personas de la República de Guatemala`
    }
    const correlative = {
      firstPart: number.substring(0, 4),
      secondPart: number.substring(4, 8)
    }
    const checker = number.substring(8, 9)
    const department = number.substring(9, 11)
    const municipality = number.substring(11, 13)

    const numberFormated = `${correlative.firstPart} ${correlative.secondPart}${checker} ${department}${municipality}`
    let textToLetters = formatText(`${correlative.firstPart}%,% ${correlative.secondPart}${checker}%,% ${department}${municipality}`)
    if (options.textTransformFormated === 'uppercase') {
      textToLetters = textToLetters.toUpperCase()
    } else if (options.textTransformFormated === 'lowercase') {
      textToLetters = textToLetters.toLowerCase()
    } else if (options.textTransformFormated === 'capitalize') {
      textToLetters = textToLetters.charAt(0).toUpperCase() + textToLetters.slice(1).toLowerCase()
    }

    if (migrationStatus && migrationStatus === EnumMigrationStatus.PERMANENT_RESIDENT) {
      return `Documento Personal de Identificación de Extranjero Domiciliado -DPI- identificado con el Código Único de Identificación -CUI- ${textToLetters} (${numberFormated}) extendido por el Registro Nacional de las Personas de la República de Guatemala`
    }
    if (type === EnumIdentificationCard.CN) {
      return `Certificado de Nacimiento con el Código Único de Identificación -CUI- ${textToLetters} (${numberFormated}) extendido por el Registro Nacional de las Personas de la República de Guatemala`
    }
    return `Documento Personal de Identificación -DPI- identificado con el Código Único de Identificación -CUI- ${textToLetters} (${numberFormated}) extendido por el Registro Nacional de las Personas de la República de Guatemala`
  }
  if (type === EnumIdentificationCard.P) {
    let textToLetters = formatText(number)
    if (options.textTransformFormated === 'uppercase') {
      textToLetters = textToLetters.toUpperCase()
    } else if (options.textTransformFormated === 'lowercase') {
      textToLetters = textToLetters.toLowerCase()
    } else if (options.textTransformFormated === 'capitalize') {
      textToLetters = textToLetters.charAt(0).toUpperCase() + textToLetters.slice(1).toLowerCase()
    }
    return `Pasaporte ${textToLetters} (${number}) extendido en ${country}`
  }
  return `Documento Personal de Identificación con el Código Único de Identificación ${number} (${number}) extendido por el Registro Nacional de las Personas${country ? ` de ${country}` : ''}`
}

export function formatBytes (bytes: number, decimals = 2) {
  if (!+bytes) return '0 B'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
