import { type ReactNode, useId, useRef } from 'react'
import { FormContext } from './form-context'
import { cn } from '@/lib/utils'

export const useIdRef = (prefix: string, initialId: string | undefined) => {
  const idRef = useRef(initialId ?? `${prefix}-${useId()}`)

  return idRef.current
}

export interface FieldProps {
  children: ReactNode
  name?: string | undefined
  messages?: string | string[] | undefined
  id?: string
  required?: boolean
  styles?: boolean
  disabled?: boolean
  className?: string
  defaultStyles?: boolean
}

export const FormField = ({
  children,
  name,
  messages = '',
  id,
  required = false,
  defaultStyles = true,
  className,
  disabled = false,
  ...props
}: FieldProps) => {
  const generateId = useIdRef('field', id)

  return (
    <div
      className={cn([
        defaultStyles && 'flex flex-col gap-1 justify-start items-start w-full',
        className
      ])}
      {...props}
    >
      <FormContext.Provider value={{
        messages,
        name,
        id: generateId,
        required,
        disabled
      }}
      >
        {children}
      </FormContext.Provider>
    </div>
  )
}
