import { type typePermission } from '@/features/roles/consts/permissions'

export enum TypeAccount {
  admin = 'admin',
  user = 'user',
  advisor = 'advisor',
}

export interface IUser {
  _id: string
  id: string
  fullname: string
  email: string
  username: string
  password?: string
  typeAccount: TypeAccount
  isTwoFactorAuthenticationEnabled?: boolean
  active?: boolean
  roles?: []
}

export interface IUserFetch extends IUser {
  updatedBy: IUser
  createdBy: IUser
}

export interface IAdvisor {
  _id: string
  id: string
  fullname: string
}

export interface IUserAuth {
  _id: string
  accessToken: string
  id: string
  username: string
  permissions?: typePermission[]
  permissionsStates?: string[]
  typeAccount: TypeAccount
}

export interface IUserCredentials {
  username: string
  password: string
  persist?: boolean
}

export interface IUserForgotPassword {
  email: string
  username: string
}

export interface IUserResetPassword {
  password: string
  confirmPassword: string
}

export interface IUserLogin {
  tokens: {
    accessToken: string
  }
  user: {
    id: string
    username: string
    fullname: string
    permissions: []
    typeAccount: TypeAccount
  }
}
