export const PRIVATE_ROUTE = {
  ADMIN: 'admin',
  UNAUTHORIZED: 'unauthorized'
} as const

export const PUBLIC_ROUTE = {
  AUTH: 'auth',
  UNAUTHORIZED: 'unauthorized'
} as const

export const ROUTES_NAME: Record<string, string> = {
  admin: 'Inicio',
  business: 'Datos de la empresa',
  clients: 'Clientes',
  config: 'Configuración'
}

export const ROUTES_ACTIONS = {
  ID: 'id',
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view'
} as const
