import { Navigate, Route } from 'react-router-dom'
import ForgotPassword from '../pages/forgot-password'
import ForgotPasswordSuccess from '../pages/forgot-password-success'
import ResetPassword from '../pages/reset-password'
import RoutesWithNotFound from '@/components/routes-with-not-found'
import Login from '../pages/login'
import TwoFactorAuth from '../pages/two-factor-auth'

export const ROUTES_AUTH = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot-password',
  FORGOT_PASSWORD_SUCCESS: 'forgot-password-success',
  RESET_PASSWORD: 'reset-password/:id/:token',
  LOGOUT: 'logout',
  TWO_FACTOR: 'two-factor'
} as const

const AuthRouter = () => {
  return (
    <RoutesWithNotFound>
      <Route path='/' element={<Navigate to={ROUTES_AUTH.LOGIN} />} />
      <Route path={ROUTES_AUTH.LOGIN} element={<Login />} />
      <Route path={ROUTES_AUTH.TWO_FACTOR} element={<TwoFactorAuth />} />
      <Route path={ROUTES_AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES_AUTH.FORGOT_PASSWORD_SUCCESS} element={<ForgotPasswordSuccess />} />
      <Route path={ROUTES_AUTH.RESET_PASSWORD} element={<ResetPassword />} />
    </RoutesWithNotFound>
  )
}

export default AuthRouter
