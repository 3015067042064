import { TypeAccount } from '@/features/users/models/user'

export const ENDPOINTS = {
  AUTH: '/auth',
  '2FA': '/2fa',
  ROLES: '/roles',
  USER: '/users',
  COMPANIES: '/companies',
  AGENCIES: '/agencies',
  MANDATARIES: '/mandataries',
  REPRESENTATIVES: '/representatives',
  COUNTRIES: '/countries',
  DEPARTMENTS: '/departments',
  MUNICIPALITIES: '/municipalities',
  PRODUCTS: '/products',
  SUBPRODUCTS: '/subproducts',
  PROFESSION: '/professions',
  GROUPS: '/groups',
  CLIENTS: '/clients',
  CLIENTS_JURIDICAL: '/clients-juridical',
  STATES: '/states',
  WORKFLOWS: '/workflows',
  CURRENCIES: '/currencies',
  CONFIGURATIONS: '/configurations',
  CATALOGS: '/catalogs',
  FOLDERS: '/folders',
  ATTACHMENTS: '/attachments',
  REPORTS: '/reports',
  SOCIETIES: '/societies',
  PROJECTS: '/projects',
  PROPERTIES: '/properties'
}

export const ENDPOINTS_USER = {
  username: 'username',
  'user-exist': 'user-exist',
  'users-not-admin': 'users-not-admin',
  verification: 'verification',
  'email-exist': 'email-exist',
  [TypeAccount.advisor]: TypeAccount.advisor,
  'is-2fa-enabled': 'is-2fa-enabled',
  '2fa-secret': '2fa-secret',
  'change-password': 'change-password',
  'change-username': 'change-username',
  'change-email': 'change-email',
  'get-username': 'get-username',
  'get-email': 'get-email',
  'update-profile': 'update-profile'
}

export const ENDPOINTS_AUTH = {
  LOGIN: 'login',
  REFRESH: 'refresh',
  'renew-data': 'renew-data',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  LOGOUT: 'logout',
  VERIFY_PASSWORD: 'verify-password',
  CHANGE_PASSWORD: 'change-password'
}

export const ENDPOINTS_AUTH_2FA = {
  AUTHENTICATE: 'authenticate',
  GENERATE: 'generate',
  TURN_ON: 'turn-on',
  TURN_OFF: 'turn-off'
}

export const ENDPOINTS_CLIENTS = {
  EXIST_IDENTIFICATION: 'exist-identification',
  NEXT_STATE: 'next-state',
  REJECT: 'reject',
  INACTIVATE: 'inactivate',
  ACTIVATE: 'activate',
  RENEW: 'renew',
  MODIFY_ADVISOR: 'modify/advisor',
  MODIFY_GROUP: 'modify/group',
  COUNT_BY_STATE: 'count-by-state'
}

export const ENDPOINTS_WORKFLOWS = {
  FLOWS_RENEW: 'flows-renew'
}

export const ENDPOINTS_ROLES = {
  ACTIVE: 'active'
}

export const ENDPOINTS_COMPANIES = {
  ACTIVE: 'active',
  IDC: 'idc'
}

export const ENDPOINTS_AGENCIES = {
  ACTIVE: 'active'
}

export const ENDPOINTS_SOCIETIES = {
  ACTIVE: 'active'
}
export const ENDPOINTS_PROJECTS = {
  ACTIVE: 'active'
}
export const ENDPOINTS_PROPERTIES = {
  ACTIVE: 'active'
}

export const ENDPOINTS_PRODUCTS = {
  ACTIVE: 'active'
}

export const ENDPOINTS_GROUPS = {
  ACTIVE: 'active'
}

export const ENDPOINTS_PROFESSIONS = {
  ACTIVE: 'active'
}

export const ENDPOINTS_SUBPRODUCTS = {
  ACTIVE: 'active'
}

export const ENDPOINTS_REPRESENTATIVES = {
  ACTIVE: 'active'
}

export const ENDPOINTS_MANDATARIES = {
  ACTIVE: 'active'
}

export const ENDPOINTS_FOLDERS = {
  FOLDER_EXIST: 'folder-exist'
}

export const ENDPOINTS_ATTACHMENTS = {
  URL: 'url'
}

export const ENDPOINTS_REPORTS = {
  'account-product': 'account-product'
}
