import useAuth from '../hooks/use-auth'
import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@/router/paths'
import { HttpStatus } from '@/config/http-status'
import { Form as FormHouse, Field as FieldHouse } from 'houseform'
import { toast } from 'sonner'
import { CODE_ERRORS } from '@/config'
import { FormLabel } from '@/components/form/form-label'
import { FormField } from '@/components/form/form-field'
import { FormInput } from '@/components/form/form-input'
import { FormMessages } from '@/components/form/form-messages'
import { Button } from '@/components/ui/button'
import Loading from '@/components/loading'
import { resetPassword, verifyTokenResetPassword } from '../auth.service'
import { joinPath } from '@/utils'
import { ROUTES_AUTH } from '../router/auth-router'
import { type IUserResetPassword } from '@/features/users/models/user'
import LayoutAuth from '../components/layout-auth'
import { TypographyParagraph } from '@/components/ui/typography/paragraph'
import { AUTH_RESET_PASSWORD_SCHEMA, AUTH_SCHEMA_VALIDATION } from '../auth.schema'
import { Loader } from 'lucide-react'

const ResetPassword = () => {
  const { id, token } = useParams()
  const navigate = useNavigate()

  const { auth } = useAuth()
  const [loadingVerify, setLoadingVerify] = useState(true)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: IUserResetPassword) => {
    try {
      setLoading(true)
      const response = await resetPassword(id, token, values)
      if (response.status === HttpStatus.OK && response.data?.success) {
        setLoading(false)
        toast.success('¡Se ha cambiado tu contraseña!')
        navigate(joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN), { replace: true })
      } else if (response.status === HttpStatus.UNAUTHORIZED) {
        setLoading(false)
        toast.error(CODE_ERRORS[response.data.message])
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await verifyTokenResetPassword(id, token)
        if (response.status === HttpStatus.OK && response.data?.id) {
          setLoadingVerify(false)
        } else if (response.status >= HttpStatus.UNAUTHORIZED) {
          setLoadingVerify(false)
          setError(true)
        }
      } catch (e) {
        console.error(e)
        setLoadingVerify(false)
        setError(true)
      }
    }
    verify()
  }, [])

  if (auth?.id) { navigate(`/${PRIVATE_ROUTE.ADMIN}`, { replace: true }) }
  if (loadingVerify) return <div className='h-screen w-full flex items-center justify-center'><Loading /></div>

  if (error) {
    return (
      <LayoutAuth
        title='Cambio de contraseña'
      >
        <TypographyParagraph className='text-center'>¡El token ha expirado o no es válido!<br />Vuelve a solicitar el cambio de contraseña</TypographyParagraph>
        <Button variant={'link'} asChild><Link to={joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.FORGOT_PASSWORD)}>Solicitar cambio de contraseña</Link></Button>
      </LayoutAuth>
    )
  }

  return (
    <LayoutAuth
      title='Cambio de contraseña'
    >
      <FormHouse
        onSubmit={handleSubmit}
      >
        {
          ({ submit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault()
                submit()
              }}
              className='grid grid-cols-1 w-full gap-6'
            >
              <FieldHouse<string>
                name={AUTH_RESET_PASSWORD_SCHEMA.PASSWORD}
                onBlurValidate={AUTH_SCHEMA_VALIDATION.password}
                onSubmitTransform={value => value.trim()}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Contraseña</FormLabel>
                      <FormInput className='lowercase' type='password' autoComplete='new-password' value={value} onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              <FieldHouse<string>
                name={AUTH_RESET_PASSWORD_SCHEMA.CONFIRM_PASSWORD}
                listenTo={[AUTH_RESET_PASSWORD_SCHEMA.PASSWORD]}
                onSubmitTransform={value => value.trim()}
                onBlurValidate={async (val, form) => {
                  if (val === form.getFieldValue(AUTH_RESET_PASSWORD_SCHEMA.PASSWORD)?.value) {
                    return true
                  } else {
                    throw 'Las contraseñas no coinciden'
                  }
                }}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Confirmación de contraseña</FormLabel>
                      <FormInput className='lowercase' type='password' autoComplete='new-password' value={value} onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              <Button type='submit'>
                {
                  loading
                    ? (<>
                      <Loader className="mr-2 h-4 w-4 animate-spin" />Cambiando contraseña...
                    </>)
                    : 'Cambiar mi contraseña'
                }

              </Button>
            </form>
          )
        }
      </FormHouse>
    </LayoutAuth>
  )
}

export default ResetPassword
