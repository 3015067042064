import { cn } from '@/lib/utils'
import { type ReactNode } from 'react'

const LoadingBrand = ({ className, children }: { className?: string, children?: ReactNode }) => {
  return (
    <div className={cn([
      'flex flex-col lg:flex-row items-center justify-center text-sm gap-4 lg:gap-6',
      className
    ])}>
      <div className='dark:bg-white p-2 container-spinner rounded-lg'>
        <div className="spinner-idc" />
      </div>
      <span className='text-[#0F2554] dark:text-white font-extrabold text-4xl'>IDC <span className='text-[#728EC2] dark:text-muted-foreground'>FORMS</span></span>
    </div>
  )
}

export default LoadingBrand
