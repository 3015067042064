import { Field, Form } from 'houseform'
import LayoutAuth from '../components/layout-auth'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot
} from '@/components/ui/input-otp'
import { FormField } from '@/components/form/form-field'
import { Button } from '@/components/ui/button'
import { z } from 'zod'
import { FormDescription } from '@/components/form/form-description'
import { useMutation } from '@tanstack/react-query'
import { axiosPrivate } from '@/api/axios'
import { CODE_ERRORS, dataProperties, ENDPOINTS, ENDPOINTS_AUTH_2FA, TYPE_ERRORS } from '@/config'
import { joinPath } from '@/utils'
import { toast } from 'sonner'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@/router/paths'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES_AUTH } from '../router/auth-router'
import useAuth from '../hooks/use-auth'
import { type IUserAuth } from '@/features/users/models/user'
import { Loader } from 'lucide-react'
import { isAxiosError } from 'axios'

interface IFormValues {
  pin: string
}

const TwoFactorAuth = () => {
  const { setAuth } = useAuth()
  const navigate = useNavigate()
  // const queryClient = useQueryClient()

  const validate2faMutation = useMutation({
    mutationFn: async (data: IFormValues) => {
      const res = await axiosPrivate.post(joinPath(ENDPOINTS['2FA'], ENDPOINTS_AUTH_2FA.AUTHENTICATE), {
        twoFactorAuthenticationCode: data.pin
      })
      return res.data
    },
    onSettled: async (data, error) => {
      if (error) {
        console.error('ERROR => ', error)
        if (isAxiosError(error) && error.response) {
          const codeError = error?.response ? error.response.data.message : TYPE_ERRORS.GENERIC_ERROR
          const message: string = CODE_ERRORS[codeError as keyof typeof CODE_ERRORS] ?? CODE_ERRORS.GENERIC_ERROR
          toast.error(message)
        }
      } else {
        toast.success('Autenticación exitosa')
        const user = data as IUserAuth

        setAuth({
          username: user.username,
          [dataProperties.uid]: user[dataProperties.uid],
          [dataProperties.id]: user[dataProperties.id],
          accessToken: user.accessToken,
          permissions: user.permissions,
          permissionsStates: user.permissionsStates,
          typeAccount: user.typeAccount
        })
        navigate(joinPath('/', PRIVATE_ROUTE.ADMIN), { replace: true })
      }
    }
  })

  const handleSubmit = async (values: IFormValues) => {
    validate2faMutation.mutate(values)
  }

  return (
    <LayoutAuth
      title='Doble Factor de Autenticación'
    // description='Ingrese el código de verificación de su aplicación de autenticación.'
    >
      <Form
        onSubmit={handleSubmit}
      >
        {
          ({ submit, isDirty }) => {
            return (
              <form
                onSubmit={e => {
                  e.preventDefault()
                  submit()
                }}
                className='flex flex-col gap-4'
              >

                <Field
                  name='pin'
                  onSubmitValidate={z.string().length(6, 'El código debe tener 6 dígitos')}
                >
                  {
                    ({ value, setValue, errors }) => (
                      <FormField required className='gap-3 items-center'>
                        <InputOTP maxLength={6} value={value} onChange={value => { setValue(value) }} className='w-fit mx-auto' autoComplete='one-time-code'>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                        {
                          errors.length > 0 && errors.map((error, index) => (
                            <span key={index} className='text-red-500 text-xs'>{error}</span>
                          ))
                        }
                        <FormDescription>Ingrese el código de verificación de su aplicación de autenticación.</FormDescription>
                      </FormField>
                    )
                  }
                </Field>

                <Button
                  type='submit'
                  disabled={validate2faMutation.status !== 'idle' || !isDirty}
                >
                  {
                    validate2faMutation.status !== 'idle'
                      ? <><Loader className='h-4 w-4 mr-2 animate-spin' /> Validando</>
                      : 'Verificar'
                    }
                </Button>

                <Button variant={'link'} asChild><Link to={joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)} replace>Regresar al inicio de sesión</Link></Button>
              </form>
            )
          }
        }
      </Form>
    </LayoutAuth>
  )
}

export default TwoFactorAuth
