import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { type TypeAccount } from '@/features/users/models/user'
import useAuth from '../hooks/use-auth'
import { joinPath } from '@/utils'
import { PUBLIC_ROUTE } from '@/router/paths'
import { ROUTES_AUTH } from '../router/auth-router'

const RequireAuth = ({ allowedAccounts = [] }: { permission?: string, actions?: string[], allowedAccounts?: TypeAccount[] }) => {
  const { auth } = useAuth()

  const location = useLocation()

  if (!auth) return <Navigate to={joinPath(PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)} state={{ from: location }} replace />
  if (auth?.typeAccount && allowedAccounts.includes(auth?.typeAccount)) return <Outlet />
  return <Navigate to={joinPath(PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)} state={{ from: location }} replace />
}

export default RequireAuth
