import { isAxiosError } from 'axios'
import axios from '@/api/axios'
import { CODE_ERRORS, ENDPOINTS, ENDPOINTS_AUTH } from '@/config'
import { type IUserAuth, type IUserCredentials, type IUserForgotPassword, type IUserResetPassword } from '../users/models/user'
import { joinPath } from '@/utils'

export const axiosErrorHandler = (e: any) => {
  if (isAxiosError(e) && e.response) {
    return e.response
  }
  return e
}

export interface IResponseUser {
  user: IUserAuth | null
  authTwoFactor?: boolean
  status: number
  error?: string
}

export const signIn = async (credentials: IUserCredentials): Promise<IResponseUser> => {
  try {
    const response = await axios.post(
      joinPath(ENDPOINTS.AUTH, ENDPOINTS_AUTH.LOGIN),
      JSON.stringify(credentials),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    )

    if (response?.data?.accessToken) {
      return {
        user: response?.data,
        status: response?.status
      }
    }

    return {
      user: null,
      authTwoFactor: true,
      status: response?.status
    }
  } catch (e) {
    console.error('ERROR::SIGN_IN => ', e)

    if (isAxiosError(e) && e.response) {
      const response = e.response
      return {
        user: null,
        status: response?.status,
        error: CODE_ERRORS[response?.data?.message as keyof typeof CODE_ERRORS]
      }
    }

    return {
      user: null,
      status: 500,
      error: CODE_ERRORS.LOGIN_ERROR
    }
  }
}

export const forgotPassword = async (credentials: IUserForgotPassword) => {
  try {
    const response = await axios.post(
      joinPath(ENDPOINTS.AUTH, ENDPOINTS_AUTH.FORGOT_PASSWORD),
      JSON.stringify(credentials),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return response
  } catch (e) {
    console.error('ERROR::FORGOT_PASSWORD => ', e)
    return axiosErrorHandler(e)
  }
}

export const verifyTokenResetPassword = async (id: string, token: string) => {
  try {
    const response = await axios.get(joinPath(ENDPOINTS.AUTH, ENDPOINTS_AUTH.RESET_PASSWORD, id, token))
    return response
  } catch (e) {
    console.error('ERROR::RESET_PASSWORD_VERIFY_TOKEN => ', e)
    return axiosErrorHandler(e)
  }
}

export const resetPassword = async (id: string, token: string, credentials: IUserResetPassword) => {
  try {
    const response = await axios.post(
      joinPath(ENDPOINTS.AUTH, ENDPOINTS_AUTH.RESET_PASSWORD, id, token),
      JSON.stringify(credentials),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return response
  } catch (e) {
    console.error('ERROR::RESET_PASSWORD => ', e)
    return axiosErrorHandler(e)
  }
}
