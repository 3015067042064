export enum EnumMigrationStatus {
  TEMPORARY_RESIDENT = '1',
  PERMANENT_RESIDENT = '2',
  TOURIST_OR_VISITOR = '3',
  WORK_PERMIT = '4',
  TRANSIT_PERSON = '5',
  CONSULAR_PERMIT_OR_SIMILAR = '6',
  NATIONALIZED_OR_NATURALIZED_IN_GUATEMALA = '7',
  OTHER = '8'
}

export const NAME_MIGRATION_STATUS = {
  1: 'Residente temporal',
  2: 'Residente permanente',
  3: 'Turista o visitante',
  4: 'Con permiso de trabajo',
  5: 'Persona en tránsito',
  6: 'Permiso consular o similar',
  7: 'Nacionalizado / Naturalizado en Guatemala',
  8: 'Otra'
} as const

export const MESSAGE_MIGRATION_STATUS = {
  1: 'Solicitar constancia de condición migratoria',
  2: 'Solicitar constancia de condición migratoria',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: ''
}

export const TYPE_MIGRATION_STATUS = {
  TEMPORARY_RESIDENT: '1',
  PERMANENT_RESIDENT: '2',
  TOURIST_OR_VISITOR: '3',
  WORK_PERMIT: '4',
  TRANSIT_PERSON: '5',
  CONSULAR_PERMIT_OR_SIMILAR: '6',
  NATIONALIZED_OR_NATURALIZED_IN_GUATEMALA: '7',
  OTHER: '8'
} as const

export type TypeMigrationStatus = typeof TYPE_MIGRATION_STATUS[keyof typeof TYPE_MIGRATION_STATUS]

export const CATALOG_MIGRATION_STATUS = [
  { value: TYPE_MIGRATION_STATUS.TEMPORARY_RESIDENT, label: NAME_MIGRATION_STATUS[1] },
  { value: TYPE_MIGRATION_STATUS.PERMANENT_RESIDENT, label: NAME_MIGRATION_STATUS[2] },
  { value: TYPE_MIGRATION_STATUS.TOURIST_OR_VISITOR, label: NAME_MIGRATION_STATUS[3] },
  { value: TYPE_MIGRATION_STATUS.WORK_PERMIT, label: NAME_MIGRATION_STATUS[4] },
  { value: TYPE_MIGRATION_STATUS.TRANSIT_PERSON, label: NAME_MIGRATION_STATUS[5] },
  { value: TYPE_MIGRATION_STATUS.CONSULAR_PERMIT_OR_SIMILAR, label: NAME_MIGRATION_STATUS[6] },
  { value: TYPE_MIGRATION_STATUS.NATIONALIZED_OR_NATURALIZED_IN_GUATEMALA, label: NAME_MIGRATION_STATUS[7] },
  { value: TYPE_MIGRATION_STATUS.OTHER, label: NAME_MIGRATION_STATUS[8] }
]
