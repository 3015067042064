import { type IUserAuth } from '@/features/users/models/user'
import { type Dispatch, type ReactNode, type SetStateAction, createContext, useState } from 'react'

export interface AuthContextType {
  auth: IUserAuth | null
  setAuth: Dispatch<SetStateAction<IUserAuth | null>>
  persist: boolean
  setPersist: Dispatch<SetStateAction<boolean>>
}

export const AuthContext = createContext<AuthContextType>({
  auth: null,
  setAuth: () => null,
  persist: false,
  setPersist: () => false
})

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<IUserAuth | null>(null)
  const [persist, setPersist] = useState<boolean>(JSON.parse(localStorage.getItem('persist')) || true)

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
